const SUPERADMIN = 1;
const COMPANY = 2;

export const menuItems = [
  // {
  //   id: 0,
  //   label: "page.main",
  //   icon: "home",
  //   link: "/",
  //   // requiresRoles: [SUPERADMIN, COMPANY],
  // },

  // Admin pages
  {
    id: 10,
    label: "page.admin.companies",
    icon: "account_circle",
    link: "/admin/companies",
    requiresAuth: true,
    requiresRoles: [SUPERADMIN],
  },
  {
    id: 11,
    label: "page.admin.users",
    icon: "handshake",
    link: "/admin/users",
    requiresAuth: true,
    requiresRoles: [SUPERADMIN],
  },  

  // Company pages
  {
    id: 20,
    label: "page.company.info",
    icon: "home",
    link: "/company/info",
    requiresAuth: true,
    requiresRoles: [COMPANY],
  },
  {
    id: 21,
    label: "page.company.setting",
    icon: "handshake",
    link: "/company/setting",
    requiresAuth: true,
    requiresRoles: [COMPANY],
  },
  {
    id: 22,
    label: "page.company.news",
    icon: "handshake",
    link: "/company/news",
    requiresAuth: true,
    requiresRoles: [COMPANY],
  },
  {
    id: 23,
    label: "page.company.cafes",
    icon: "handshake",
    link: "/company/cafes",
    requiresAuth: true,
    requiresRoles: [COMPANY],
  },
  {
    id: 24,
    label: "page.company.users",
    icon: "handshake",
    link: "/company/users",
    requiresAuth: true,
    requiresRoles: [COMPANY],
  },
];
