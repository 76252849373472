<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row align-items-center text-center">
        <div class="col-md-6 text-md-left my-2">
          {{ new Date().getFullYear() }} &copy; {{ $t('app.title') }}. {{ $t('footer.all-rights-reserved') }}
        </div>
        <div class="col-md-6 text-md-right my-2">
          Email: support@mail.com
        </div>
      </div>
    </div>
  </footer>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {

    };
  },
};
</script>
